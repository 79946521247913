import { Content } from "@prismicio/client";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import style from "./HomeOpeInfo.module.scss";

export type HomeOpeInfoProps = SliceComponentProps<Content.HomeOpeInfoSlice>;

const HomeOpeInfo = ({ slice }: HomeOpeInfoProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={style.section}
    >
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className={style.description}>
            <PrismicRichText field={slice.primary.description} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeOpeInfo;
